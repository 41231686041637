@import '../../styles.scss';

@mixin filter--hotPink {
  filter: invert(20%) sepia(71%) saturate(3384%) hue-rotate(315deg) brightness(96%) contrast(91%);
}

.case-study {
  img {
    width: 100%;
  }

  .CS__mobile-mockup {
    text-align: center;

    // .mobile-mockup__glow {
    //   position: absolute;
    //   width: 100%;
    //   bottom: 0;
    //   transform: translateY(100%);
    //   z-index: 1;
    //   filter: blur(8px);

    //   @include effect--fadeInOut(2s);

    //   &:after {
    //     content: '';
    //     bottom: 0;
    //     height: 100px;
    //     width: 100%;
    //     max-width: 450px;
    //     margin: 0 auto;
    //     background: linear-gradient(180deg, $hotPink 0%, $midnight 100%);
    //     display: block;
    //     clip-path: polygon(48% 1%, 100% 40%, 52% 100%, 35% 89%);
    //     // transform: rotate(45deg);
    //   }
    // }

    img {
      max-width: 450px;
      position: inherit;
      z-index: $cs-mobile-mockup;
    }
  }
}

.AppleII__masthead {
  font-family: $font-family_Apple-II !important;
  display: flex;
  box-orient: vertical;
  box-direction: normal;
  flex-direction: column;
  background-color: white;
  border: 1px solid black;
  box-shadow: 4px 4px 0 black;
  margin: 5% auto;
  overflow: hidden;
  position: relative;
  float: none;
  width: 100%;

  .title-bar {
    box-align: center;
    align-items: center;
    display: flex;
    box-orient: horizontal;
    box-direction: normal;
    flex-direction: row;
    box-pack: center;
    justify-content: center;
    border: 1px solid black;
    min-height: 30px;
    position: relative;
    text-align: center;
    width: 100%;

    &:before,
    &:after {
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAJCAYAAAALpr0TAAAAG0lEQVQYV2NkIBIwAtX9J0YtSCFRgCSFw8ZqAGFpAwfpRLQ2AAAAAElFTkSuQmCC');
      content: '';
      height: 18px;
      left: 0;
      position: absolute;
      right: 0;
      top: 7px;
    }

    ul {
      list-style: none;
    }

    .title {
      color: #000000;
      background: white;
      border: 6px solid white;
      z-index: $appleII-title;
    }

    .icon--close {
      height: 18px;
      width: 18px;
      background: white;
      border: 2px solid black;
      left: 2%;
      position: absolute;
      top: 7px;
      z-index: $appleII-close--icon;
      cursor: pointer;

      &:hover {
        background: url('../../assets/case-studies/apple2-action.png') center no-repeat, #ffffff;
        background-size: cover;
      }
    }

    .icon--maximize {
      height: 18px;
      width: 18px;
      background: white;
      border: 2px solid black;
      position: absolute;
      right: 2%;
      top: 7px;
      z-index: $appleII-maximize--icon;
      cursor: pointer;

      &:after {
        height: 8px;
        width: 8px;
        border: 2px solid black;
        content: '';
        left: -2px;
        position: absolute;
        top: -2px;
      }
    }
  }

  .content {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;

    @media (max-width: $tablet-l) {
      height: 150px;
    }
  }

  .masthead__brand-logo {
    filter: grayscale(100%) blur(1px);
    image-rendering: pixelated;
    width: 69%;
    transition: 0.4s;
  }

  // must declare after everything has rendered first
  &:not(.dark) .content:hover .masthead__brand-logo {
    image-rendering: auto !important;
    animation: fadeup 0.8s ease-in-out forwards;

    @keyframes fadeup {
      50% {
        opacity: 0;
        transform: translateY(50%);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
        filter: none;
      }
    }
  }

  .scroll-bar--bottom {
    background: white;
    border: 1px solid black;
    position: absolute;
    bottom: 0;
    width: 100%;

    &:before,
    &:after {
      transform: rotate(-90deg);
    }

    &:after {
      margin-right: 27px;
      position: absolute;
      right: 0;
      transform: rotate(90deg);
    }
  }

  .scroll-bar--right {
    background: white;
    border: 1px solid black;
    margin-top: 55px;
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAGklEQVQIW2NkYGD4D8SMQAwGcAY2AbBKDBUAVuYCBQPd34sAAAAASUVORK5CYII=)
      repeat;
    // bottom: 26px;
    right: 0;
    top: 0;
    height: calc(100% - 109px);

    &:after {
      margin-bottom: -27px;
      transform: rotateX(180deg);
      bottom: 0;
      left: 0;
      position: absolute;
    }

    &:before {
      margin-top: -27px;
      margin-right: -1px;
    }

    .theme-change {
      height: 24px;
      width: 24px;
      background: url('../../assets/case-studies/theme-change.png') center no-repeat;
      bottom: -53px;
      position: absolute;
      right: 0;
      z-index: $appleII-theme-change--icon;
      cursor: pointer;
      @include effect--floating(3s);
    }
  }

  .scroll-bar--bottom:before,
  .scroll-bar--bottom:after,
  .scroll-bar--right:before,
  .scroll-bar--right:after {
    height: 25px;
    width: 25px;
    background: url('../../assets/case-studies/apple2-arrow.png') center/16px no-repeat;
    border: 1px solid black;
    content: '';
    float: left;
    z-index: $appleII-scrollbar;
    margin-left: -1px;
  }
}

// Darkmode
.AppleII__masthead.dark {
  transition: 0.2s;
  background-color: $haunt;
  border: 1px solid $hotPink;
  box-shadow: 6px 6px 12px rgba($hotPink, 0.6);

  .title-bar {
    border: 1px solid $hotPink;

    &:before,
    &:after {
      // https://stackoverflow.com/questions/42966641/how-to-transform-black-into-any-given-color-using-only-css-filters/43960991
      @include filter--hotPink();
    }

    .title {
      font-family: $font-family_roadRage;
      color: $hotPink;
      background: $haunt;
      border: 6px solid $haunt;
    }

    .icon--close {
      background: $haunt;
      border: 2px solid $hotPink;
    }

    .icon--maximize {
      background: $haunt;
      border: 2px solid $hotPink;

      &:after {
        border: 2px solid $hotPink;
      }
    }
  }

  .masthead__brand-logo {
    filter: invert(100%) grayscale(100%);
  }

  .scroll-bar--bottom {
    background: $haunt;
    border: 1px solid $hotPink;
  }

  .scroll-bar--right {
    // background: $haunt;
    border: 1px solid $hotPink;
    @include filter--hotPink();
  }

  .scroll-bar--bottom:before,
  .scroll-bar--bottom:after,
  .scroll-bar--right:before,
  .scroll-bar--right:after {
    // height: 23px;
    // width: 25px;
    border: 1px solid $hotPink;

    @include filter--hotPink();
  }

  .theme-change {
    @include filter--hotPink();
  }
}
