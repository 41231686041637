// Inspiration provide by Bartosz Sepioło https://codepen.io/bsepiolo/pen/rNVYvWy?editors=1010

.ReactModalPortal {
  position: relative;
  z-index: $modal;
}

.ReactModal__Content {
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.85) !important;
  opacity: 0;
  transition: opacity 800ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  position: relative;
  height: calc(100% - 55px);

  .title__wrap {
    position: relative;
    top: 0px;
    width: 20%;
    min-width: 120px;

    &:after {
      position: absolute;
      content: '';
      display: block;
      height: 2px;
      width: 32px;
      background: $hotPink;
      right: -29px;
      -webkit-clip-path: polygon(0 0, calc(100% - 2px) 0%, 100% 100%, 22% 100%);
      clip-path: polygon(0 0, calc(100% - 2px) 0%, 100% 100%, 0% 100%);
      bottom: 2px;
    }
  }

  .content__container {
    clip-path: polygon(0% 0%, calc(100% - 26px) 0, 100% 26px, 100% 100%, 95% 100%, calc(0% + 26px) 100%, 0% calc(100% - 26px), 0% calc(100% + 26px));
    border: 2px solid;
    border-width: 2px;
    border-image-source: linear-gradient(to left, $electroShock, $hotPink);
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 2px;
    border-image-slice: 1;

    &:before,
    &:after {
      content: '';
      height: 2px;
      width: 34px;
      display: block;
      position: absolute;
      z-index: $modal;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      border-radius: 5px;
    }
    &:before {
      right: -6px;
      top: 11px;
      background: $electroShock;
    }
    &:after {
      left: -6px;
      bottom: 11px;
      background: $hotPink;
    }
  }

  .content-stylistic__bottom {
    position: absolute;
    content: '';
    display: block;
    height: 6;
    width: 30%;
    background: $hotPink;
    right: 0px;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, calc(0% + 2px) 100%);
    bottom: 0px;
    transform: translateY(18px);
  }

  h2 {
    font-size: $font-size-40;

    @media (max-width: $tablet-l) {
      font-size: $font-size-32;
    }
  }

  .title {
    position: relative;
    display: block;
    height: 30px;
    width: auto;
    background: $hotPink;
    left: 0px;
    color: #ffffff;
    font-size: $font-size-10;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    font-weight: 600;
    padding: 0 24px 0 30px;
    display: flex;
    align-items: center;
    clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 100%, 0% 100%);
  }
  .content {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: rgba(0, 0, 0, 0.85);
    overflow: scroll;
    color: #ffffff;
    padding: 30px;
    letter-spacing: 0.2px;
    overflow: hidden scroll;

    &::-webkit-scrollbar {
      background: rgba(255, 255, 255, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 8px;
    }
  }
}

.btn__container {
  position: absolute;
  height: 50px;
  right: 0;
  transform: translateX(-1%);
  cursor: pointer;

  // &:before,
  // &:after {
  //   content: '';
  //   height: 2px;
  //   width: 11px;
  //   background: $hotPink;
  //   display: block;
  //   position: absolute;
  //   z-index: $modal;
  //   -webkit-transform: rotate(45deg);
  //   transform: rotate(45deg);
  //   border-radius: 2px;
  //   border-radius: 2px;
  // }

  &:before {
    right: -1.07px;
    top: 4px;
  }

  &:after {
    left: -1.07px;
    bottom: 6px;
  }

  .btn {
    background: $miamiSun;
    display: flex;
    align-items: center;
    line-height: 10px;
    pointer-events: none;
    color: $shadow;
    font-weight: bold;
    padding: 0 50px;
    height: 50px;
    // border: 2px solid $hotPink;
    clip-path: polygon(0% 0%, calc(100% - 12px) 0, 100% 12px, 100% 100%, 95% 100%, calc(0% + 12px) 100%, 0% calc(100% - 12px), 0% 20%);
  }

  .btn-stylistic__bottom {
    position: absolute;
    content: '';
    display: block;
    height: 5px;
    width: 50%;
    background: $miamiSun;
    pointer-events: none;
    right: 0px;
    bottom: -3px;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, calc(0% + 4px) 100%);
    border-bottom: 2px solid lighten($miamiSun, 10%);
    border-right: 2px solid lighten($miamiSun, 10%);

    &:before {
      content: '';
      height: 2px;
      width: 11px;
      background: lighten($miamiSun, 10%);
      display: block;
      position: absolute;
      left: -5px;
      bottom: 2px;
      z-index: $modal;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}
