$mobile-s: 320px;
$mobile: 375px;
$mobile-l: 425px;
$tablet-s: 580px;
$tablet: 768px;
$tablet-l: 1024px;
$desktop: 1200px;

:export {
  mobile-s: $mobile-s;
  mobile: $mobile;
  mobile-l: $mobile-l;
  tablet-s: $tablet-s;
  tablet: $tablet;
  tablet-l: $tablet-l;
  desktop: $desktop;
}
