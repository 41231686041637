// Specific typeface display larger than container, due to typeface design
@mixin font-overlay-fix {
  padding: 60px;
}

.error__container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  .glitch__container {
    overflow: hidden;
    display: flex;
    align-items: center;
    font-family: $font-family_roadRage;
    font-size: 18rem;
    pointer-events: none;
    @include effect--fadeIn(2s, 0.5s);

    @media (max-width: $tablet) {
      font-size: 10rem;
    }
  }

  .overlay {
    position: absolute;
    z-index: $error-overlay;
    line-height: 1;
    background-size: 150%;
    background-position: bottom;
    animation: panUpDown 10s ease-in-out infinite;

    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;

    @include font-overlay-fix;
  }

  .glitch {
    color: $hotPink;
    position: relative;
    left: 0;
    display: inline-block;
    line-height: 1;

    @include font-overlay-fix;

    &:before,
    &:after {
      content: attr(error);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $midnight;
      @include font-overlay-fix;
    }
    &:before {
      left: 2px;
      clip: rect(24px, 550px, 90px, 0);
      animation: glitch-anim-2 3s infinite linear alternate-reverse;
    }
    &:after {
      left: -2px;
      clip: rect(85px, 550px, 140px, 0);
      animation: glitch-anim 2.5s infinite linear alternate-reverse;
    }
  }

  .error-message__container {
    margin: 36px 0;
    .error__message {
      opacity: 0;
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      border-right: 0.1em solid $hotPink; /* The typwriter cursor */
      white-space: nowrap; /* Keeps the content on a single line */
      margin: 0 auto; /* Gives that scrolling effect as the typing happens */
      letter-spacing: 0.15em; /* Adjust as needed */
      opacity: 0;
      animation: typing-effect 1.5s 2s steps(11, end) forwards, blink-cursor 0.8s step-end infinite forwards;
    }

    .error__message .spacer {
      width: 45px;
      display: inline-block;
    }
  }

  .ET-home-phone {
    padding: 8px 16px;
    border: 1px solid $hotPink;
    transition: border 1s color 1s;
    background: transparent;
    color: $hotPink;

    @include effect--fadeIn(2s, 0.8s);

    &:hover,
    &:focus {
      border: 2px solid $electroShock;
      color: $electroShock;
      box-shadow: 0px 0 1px rgba(242, 27, 185, 0.5), 0px 0 5px rgba(242, 27, 185, 0.5), 0px 0 10px rgba(242, 27, 185, 0.5), 0px 0 15px rgba(242, 27, 185, 0.5),
        0px 0 20px rgba(242, 27, 185, 0.5);
    }
  }

  .error__social-media {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid $hotPink;
    @include effect--fadeIn(2s, 1s);

    transition: 2s ease-in-out;

    .network__list {
      flex-direction: column;
      transform: translateX(50%);
      background: $midnight;
    }

    .network__list .network__item {
      &:first-of-type {
        margin-top: 20px;
      }
      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    .network__list svg {
      fill: $hotPink;
      transform: scale(0.75);
    }

    @media screen and (max-width: $mobile-l) {
      top: unset;
      bottom: 0;
      height: unset;
      border: none;
      width: 100%;
      justify-content: center;

      .network__list {
        flex-direction: row;
        transform: translateX(0);
        width: 100%;
        justify-content: space-evenly;
      }

      .network__item {
        margin: 0 !important;
      }
    }
  }
}

@keyframes typing-effect {
  0% {
    padding-left: 20px;
    opacity: 1;
    width: 15%;
  }
  to {
    opacity: 1;
    width: 100%;
  }
}

@keyframes blink-cursor {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: $hotPink;
  }
}

@keyframes panUpDown {
  50% {
    background-position: top;
  }
}

@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(10px, 9999px, 500px, 0);
  }
  4% {
    clip: rect(123px, 9999px, 121px, 0);
  }
  8% {
    clip: rect(100px, 9999px, 132px, 0);
  }
  12% {
    clip: rect(18px, 9999px, 127px, 0);
  }
  16% {
    clip: rect(104px, 9999px, 500px, 0);
  }
  20% {
    clip: rect(13px, 9999px, 18px, 0);
  }
  25% {
    clip: rect(84px, 9999px, 89px, 0);
  }
  29% {
    clip: rect(9px, 9999px, 138px, 0);
  }
  33% {
    clip: rect(2px, 9999px, 5px, 0);
  }
  37% {
    clip: rect(80px, 9999px, 500px, 0);
  }
  41% {
    clip: rect(109px, 9999px, 116px, 0);
  }
  45% {
    clip: rect(72px, 9999px, 41px, 0);
  }
  50% {
    clip: rect(5px, 9999px, 500px, 0);
  }
  54% {
    clip: rect(2px, 9999px, 147px, 0);
  }
  58% {
    clip: rect(2px, 9999px, 90px, 0);
  }
  62% {
    clip: rect(34px, 9999px, 125px, 0);
  }
  66% {
    clip: rect(42px, 9999px, 28px, 0);
  }
  70% {
    clip: rect(6px, 9999px, 36px, 0);
  }
  75% {
    clip: rect(13px, 9999px, 78px, 0);
  }
  79% {
    clip: rect(104px, 9999px, 82px, 0);
  }
  83% {
    clip: rect(79px, 9999px, 27px, 0);
  }
  87% {
    clip: rect(122px, 9999px, 144px, 0);
  }
  91% {
    clip: rect(20px, 9999px, 79px, 0);
  }
  95% {
    clip: rect(4px, 9999px, 4px, 0);
  }
  100% {
    clip: rect(94px, 9999px, 57px, 0);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(10px, 9999px, 124px, 0);
  }
  4% {
    clip: rect(123px, 9999px, 500px, 0);
  }
  8% {
    clip: rect(100px, 9999px, 132px, 0);
  }
  12% {
    clip: rect(18px, 9999px, 127px, 0);
  }
  16% {
    clip: rect(104px, 9999px, 14px, 0);
  }
  20% {
    clip: rect(13px, 9999px, 500px, 0);
  }
  25% {
    clip: rect(84px, 9999px, 89px, 0);
  }
  29% {
    clip: rect(9px, 9999px, 500px, 0);
  }
  33% {
    clip: rect(2px, 9999px, 5px, 0);
  }
  37% {
    clip: rect(80px, 9999px, 16px, 0);
  }
  41% {
    clip: rect(109px, 9999px, 116px, 0);
  }
  45% {
    clip: rect(72px, 9999px, 41px, 0);
  }
  50% {
    clip: rect(5px, 9999px, 122px, 0);
  }
  54% {
    clip: rect(2px, 9999px, 147px, 0);
  }
  58% {
    clip: rect(2px, 9999px, 90px, 0);
  }
  62% {
    clip: rect(34px, 9999px, 125px, 0);
  }
  66% {
    clip: rect(42px, 9999px, 28px, 0);
  }
  70% {
    clip: rect(6px, 9999px, 36px, 0);
  }
  75% {
    clip: rect(13px, 9999px, 78px, 0);
  }
  79% {
    clip: rect(104px, 9999px, 82px, 0);
  }
  83% {
    clip: rect(79px, 9999px, 27px, 0);
  }
  87% {
    clip: rect(122px, 9999px, 144px, 0);
  }
  91% {
    clip: rect(20px, 9999px, 79px, 0);
  }
  95% {
    clip: rect(4px, 9999px, 4px, 0);
  }
  100% {
    clip: rect(94px, 9999px, 57px, 0);
  }
}

@-webkit-keyframes glitch-anim-2 {
  6% {
    clip: rect(133px, 9999px, 101px, 0);
  }
  10% {
    clip: rect(105px, 9999px, 500px, 0);
  }
  13% {
    clip: rect(18px, 9999px, 85px, 0);
  }
  16% {
    clip: rect(48px, 9999px, 90px, 0);
  }
  20% {
    clip: rect(73px, 9999px, 58px, 0);
  }
  23% {
    clip: rect(134px, 9999px, 55px, 0);
  }
  26% {
    clip: rect(81px, 9999px, 100px, 0);
  }
  30% {
    clip: rect(98px, 9999px, 108px, 0);
  }
  33% {
    clip: rect(147px, 9999px, 55px, 0);
  }
  36% {
    clip: rect(77px, 9999px, 89px, 0);
  }
  40% {
    clip: rect(99px, 9999px, 32px, 0);
  }
  43% {
    clip: rect(102px, 9999px, 41px, 0);
  }
  46% {
    clip: rect(30px, 9999px, 111px, 0);
  }
  50% {
    clip: rect(87px, 9999px, 93px, 0);
  }
  53% {
    clip: rect(64px, 9999px, 101px, 0);
  }
  56% {
    clip: rect(110px, 9999px, 23px, 0);
  }
  60% {
    clip: rect(85px, 9999px, 103px, 0);
  }
  63% {
    clip: rect(94px, 9999px, 47px, 0);
  }
  66% {
    clip: rect(59px, 9999px, 89px, 0);
  }
  70% {
    clip: rect(0px, 9999px, 88px, 0);
  }
  73% {
    clip: rect(81px, 9999px, 79px, 0);
  }
  76% {
    clip: rect(146px, 9999px, 28px, 0);
  }
  80% {
    clip: rect(120px, 9999px, 500px, 0);
  }
  83% {
    clip: rect(53px, 9999px, 77px, 0);
  }
  86% {
    clip: rect(124px, 9999px, 73px, 0);
  }
  90% {
    clip: rect(59px, 9999px, 101px, 0);
  }
  93% {
    clip: rect(99px, 9999px, 128px, 0);
  }
  96% {
    clip: rect(24px, 9999px, 11px, 0);
  }
  100% {
    clip: rect(82px, 9999px, 102px, 0);
  }
}

@keyframes glitch-anim-2 {
  6% {
    clip: rect(133px, 9999px, 101px, 0);
  }
  10% {
    clip: rect(105px, 9999px, 500px, 0);
  }
  13% {
    clip: rect(18px, 9999px, 85px, 0);
  }
  16% {
    clip: rect(48px, 9999px, 90px, 0);
  }
  20% {
    clip: rect(73px, 9999px, 58px, 0);
  }
  23% {
    clip: rect(134px, 9999px, 500px, 0);
  }
  26% {
    clip: rect(81px, 9999px, 100px, 0);
  }
  30% {
    clip: rect(98px, 9999px, 108px, 0);
  }
  33% {
    clip: rect(147px, 9999px, 55px, 0);
  }
  36% {
    clip: rect(77px, 9999px, 89px, 0);
  }
  40% {
    clip: rect(99px, 9999px, 32px, 0);
  }
  43% {
    clip: rect(102px, 9999px, 41px, 0);
  }
  46% {
    clip: rect(30px, 9999px, 111px, 0);
  }
  50% {
    clip: rect(87px, 9999px, 93px, 0);
  }
  53% {
    clip: rect(64px, 9999px, 101px, 0);
  }
  56% {
    clip: rect(110px, 9999px, 23px, 0);
  }
  60% {
    clip: rect(85px, 9999px, 103px, 0);
  }
  63% {
    clip: rect(94px, 9999px, 47px, 0);
  }
  66% {
    clip: rect(59px, 9999px, 89px, 0);
  }
  70% {
    clip: rect(0px, 9999px, 88px, 0);
  }
  73% {
    clip: rect(81px, 9999px, 79px, 0);
  }
  76% {
    clip: rect(146px, 9999px, 28px, 0);
  }
  80% {
    clip: rect(120px, 9999px, 120px, 0);
  }
  83% {
    clip: rect(53px, 9999px, 77px, 0);
  }
  86% {
    clip: rect(124px, 9999px, 73px, 0);
  }
  90% {
    clip: rect(59px, 9999px, 101px, 0);
  }
  93% {
    clip: rect(99px, 9999px, 128px, 0);
  }
  96% {
    clip: rect(24px, 9999px, 11px, 0);
  }
  100% {
    clip: rect(82px, 9999px, 102px, 0);
  }
}
