// Synth Wave Pallet
$shadow: #1b1c1d;
$haunt: #2c2d44;
$midnight: #0e141b;
$electicPurple: #492073;
$ferrari: #ff2800;
$purple: #990077;
$hotPink: #ff0a78;
$pinkLemonade: #f373d1;
$eel: #06334f;
$electroShock: #5ae1ff;
$tigerMilk: #f2a74b;
$miamiSun: #f6ef00;
$bloodOrange: #f2865e;
$nightWolf: #7e8c9a;

.color {
	&--shadow { color: $shadow; }
	
	&--haunt { color: $haunt; }
	
	&--midnight { color: $midnight; }
	
	&--electicPurple { color: $electicPurple; }
	
	&--ferrari { color: $ferrari; }
	
	&--purple { color: $purple; }
	
	&--hotPink { color: $hotPink; }
	
	&--pinkLemonade { color: $pinkLemonade; }
	
	&--eel { color: $eel; }
	
	&--electroShock { color: $electroShock; }
	
	&--tigerMilk { color: $tigerMilk; }
	
	&--miamiSun { color: $miamiSun; }
	
	&--bloodOrange { color: $bloodOrange; }
	
	&--nightWolf { color: $nightWolf; }
}

@mixin text-shadow($color) {
	text-shadow: 
		0 0 5px darken($color, 50%),
		0 0 10px rgba($color, 0.8),
		0 0 20px rgba($color, 0.6),
		0 0 40px rgba($color, 0.4);
}

@mixin text-anaglyph($color) {
	box-sizing: content-box;
  border: none;
  color: $color;
  text-overflow: clip;
  letter-spacing: 3px;
  text-shadow: -3px 0 1px rgba($electroShock, 0.5), 3px 0 1px rgba($hotPink, 0.5);
}


@mixin drop-shadow($color) {
	filter:
	// drop-shadow(0 0 10px rgba($color, 0.8))
	// drop-shadow(0 0 20px rgba($color, 0.6))
	// drop-shadow(0 0 40px rgba($color, 0.4));
		drop-shadow(0 0 20px rgba($color, 1));
}


$dribbble: #ea4c89;
$linkedin: #0077b5;
$stackshare: #0690fa;
$twitter: #1da1f2;
$codepen: #000;
$instagram: #e4405f;
$keybase: #33a0ff;
$googleplay: #414141;
$spotify: #1ed760;
