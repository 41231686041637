// RoadRage
@font-face {
    font-family: 'RoadRage';
		src: url('../assets/fonts/RoadRage/RoadRage.eot');
    src: url('../assets/fonts/RoadRage/RoadRage.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/RoadRage/RoadRage.woff') format('woff'),
        url('../assets/fonts/RoadRage/RoadRage.ttf') format('truetype'),
        url('../assets/fonts/RoadRage/RoadRage.svg#GantModern-Regular') format('svg');
    font-style: normal;
    font-weight: normal;
}

// Neoneon
@font-face {
    font-family: 'Neoneon';
		src: url('../assets/fonts/Neoneon/Neoneon.eot');
    src: url('../assets/fonts/Neoneon/Neoneon.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Neoneon/Neoneon.woff') format('woff'),
        url('../assets/fonts/Neoneon/Neoneon.ttf') format('truetype'),
        url('../assets/fonts/Neoneon/Neoneon.svg#GantModern-Regular') format('svg');
    font-style: normal;
    font-weight: normal;
}

// Inter
@each $InterLoop in Inter-Black,
Inter-Bold,
Inter-ExtraBold,
Inter-ExtraLight,
Inter-Light,
Inter-Medium,
Inter-Regular,
Inter-SemiBold,
Inter-Thin
{
  @font-face {
    font-family: #{$InterLoop};
		src: url('../assets/fonts/Inter/#{$InterLoop}.woff') format('woff'),
				 url('../assets/fonts/Inter/#{$InterLoop}.woff2') format('woff2'),
         url('../assets/fonts/Inter/#{$InterLoop}.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
	}
	// Neat trick by Oliver Davies
}

// FiraCode
@each $FiraLoop in 
FiraCode-Bold,
FiraCode-Light,
FiraCode-Medium,
FiraCode,
FiraCode-SemiBold,
FiraCode-VF
{
  @font-face {
    font-family: #{$FiraLoop};
		src: url('../assets/fonts/FiraCode/#{$FiraLoop}.woff') format('woff'),
				 url('../assets/fonts/FiraCode/#{$FiraLoop}.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
	}
}

@each $FelixLoop in 
'Felix Clean',
Felix
{
  @font-face {
    font-family: #{$FelixLoop};
		src: url('../assets/fonts/Felix/#{$FelixLoop}.otf') format('otf'),
				 url('../assets/fonts/Felix/#{$FelixLoop}.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
	}
}

@each $RecursiveLoop in Recursive-Light, Recursive
{
  @font-face {
    font-family: #{$RecursiveLoop};
		src: url('../assets/fonts/Recursive/#{$RecursiveLoop}.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
	}
}


// Apple2
@font-face {
    font-family: 'Apple-II';
		src: url('../assets/fonts/Apple-II/PrintChar21.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}