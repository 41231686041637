@import './colors.scss';
@import './typography.scss';
@import './animations.scss';
@import './breakpoints.scss';

* {
  box-sizing: border-box;

  &:focus {
    outline: none; //Advice against, not great for A11Y
  }
}

html,
body {
  margin: 0;
  padding: 0;
  background: $midnight !important;
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
  color: #fff;
  font-size: $font-size-base;
  font-family: $font-family_recursiveLight, 'Lato', 'Helvetica Neue', Arial, sans-serif;
}

#root {
  background: $midnight !important; //Needed otherwise weird....background artifact issue occur
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $hotPink;
  margin-bottom: 0.8rem;
}

h1,
h2,
h3,
h4 {
  font-family: $font-family_felix;
  @include text-anaglyph(#fff);
}

h1 {
  width: 100%;
  text-transform: capitalize;

  &.page-masthead {
    text-align: center;
    font-size: $font-size-112;
    margin: 20% 0;
  }
}

h2 {
  text-align: center;
  text-transform: capitalize;
  font-size: $font-size-48;
  margin-bottom: 30px;
}

a,
.fake-link {
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $hotPink;

    svg {
      transition: 0.3s;
      fill: $hotPink;
    }
  }
}

.list-style--none {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;

  > li:before,
  &:before {
    display: none !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// A11y
a span {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}

p a {
  color: $hotPink;
}

header:not(.CS__masthead),
section,
.content-padding {
  margin-bottom: 25%;
}

section {
  position: relative;
}

header,
main,
footer {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 1024px;
  position: relative;
}
header {
  margin-top: 105px; //safe as nav height
}

main {
  padding-bottom: 15%;
  background: $midnight;
}

::selection {
  background: $hotPink;
  color: #fff;
  text-shadow: none;
}

p {
  margin: 0 0 1em;
  line-height: 1.4285em;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

.desktop-hide {
  @media (min-width: $tablet-l) {
    display: none;
  }
}
