@import './typography-fontfaces.scss';

.align-l {
  text-align: left;
}
.align-c {
  text-align: center;
}
.align-r {
  text-align: right;
}

$font-family_Apple-II: 'Apple-II', arial, sans-serif;
$font-family_FiraCode-Bold: 'FiraCode-Bold', arial, sans-serif;
$font-family_FiraCode-Light: 'FiraCode-Light', arial, sans-serif;
$font-family_FiraCode-Medium: 'FiraCode-Medium', arial, sans-serif;
$font-family_FiraCode: 'FiraCode', arial, sans-serif;
$font-family_FiraCode-SemiBold: 'FiraCode-SemiBold', arial, sans-serif;
$font-family_FiraCode-VF: 'FiraCode-VF', arial, sans-serif;

$font-family_interBold: 'Inter-Bold', arial, sans-serif;
$font-family_interExtraBold: 'Inter-ExtraBold', arial, sans-serif;
$font-family_interExtraLight: 'Inter-ExtraLight', arial, sans-serif;
$font-family_interLight: 'Inter-Light', arial, sans-serif;
$font-family_interMedium: 'Inter-Medium', arial, sans-serif;
$font-family_inter: 'Inter-Regular', arial, sans-serif;
$font-family_interSemiBold: 'Inter-SemiBold', arial, sans-serif;
$font-family_interThin: 'Inter-Thin', arial, sans-serif;

$font-family_neoneon: 'Neoneon', arial, sans-serif;

$font-family_roadRage: 'RoadRage', arial, sans-serif;

$font-family_felix: 'Felix', arial, sans-serif;
$font-family_felix-clean: 'Felix Clean', 'RoadRage', arial, sans-serif;

$font-family_recursive: 'Recursive', arial, sans-serif;
$font-family_recursiveLight: 'Recursive-Light', arial, sans-serif;

// Font Size
$font-size-base: 14px;
$font-size-10: 0.625rem; // 10px
$font-size-12: 0.75rem; // 12px
$font-size-14: 0.875rem; // 14px
$font-size-16: 1rem; // 16px
$font-size-18: 1.125rem; // 18px
$font-size-20: 1.25rem; // 20px
$font-size-24: 1.5rem; // 24px
$font-size-28: 1.75rem; // 28px
$font-size-32: 2rem; // 32px
$font-size-36: 2.25rem; // 36px
$font-size-40: 2.5rem; // 40px
$font-size-42: 2.625rem; // 42px
$font-size-48: 3rem; // 48px
$font-size-54: 3.375rem; // 54px
$font-size-60: 3.75rem; // 60px
$font-size-68: 4.25rem; // 68px
$font-size-84: 5.25rem; // 84px
$font-size-92: 5.75rem; // 92px
$font-size-112: 8rem; // 112px

// Line Height
$line-height-xl: 2em;
$line-height-l: 1.75em;
$line-height-m: 1.5em;
$line-height-s: 1.25em;

// Letter Spacing
$letter-spacing-xl: 4px;
$letter-spacing-l: 1.3px;
$letter-spacing-m: 0.05px;
$letter-spacing-s: 0.01px;
