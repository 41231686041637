// PULSE
@keyframes pulse_hotPink {
  0%   {filter: drop-shadow(2px 2px 3px rgba($hotPink, 1));}
	50%  {filter: drop-shadow(2px 2px 7px rgba($hotPink, 0.6));}
	100% {filter: drop-shadow(2px 2px 3px rgba($hotPink, 1));}
}

// FLICKER
@keyframes flicker {
  0%   { opacity: 0.1; @include text-shadow($hotPink) }
  2%   { opacity: 1;   @include text-shadow($hotPink) }
  8%   { opacity: 0.1; @include text-shadow($hotPink) }
  9%   { opacity: 1;   @include text-shadow($hotPink) }
  12%  { opacity: 0.1; @include text-shadow($hotPink) }
  20%  { opacity: 1;   @include text-shadow($hotPink) }
  25%  { opacity: 0.3; @include text-shadow($hotPink) }
  30%  { opacity: 1;   @include text-shadow($hotPink) }
  70%  { opacity: 0.7; @include text-shadow($hotPink) }
  72%  { opacity: 0.2; @include text-shadow($hotPink) }
  77%  { opacity: 0.9; @include text-shadow($hotPink) }
  100% { opacity: 0.9; @include text-shadow($hotPink) }
}

//effect--floating
@mixin effect--floating ($sec) {
  $name: effect--floating;
	animation: $name $sec infinite ease-in-out;
	
	@keyframes effect--floating {
		0% {		transform: translatey(0px);	}
		50% {		transform: translatey(-15%);	}
		100% {	transform: translatey(0px);	}
	}
}


//Fade In
@mixin effect--fadeIn($sec, $delay) {
  opacity: 0;
  $name: keyframe-effect--fadeIn;
	animation: $name $sec $delay forwards ease-in;
	
	@keyframes keyframe-effect--fadeIn {
		from { opacity: 0; }
		to { opacity: 1; }
	}
}

// Fade In / Out
@mixin effect--fadeInOut($sec) {
  opacity: 0;
  $name: "effect--fadeInOut";
	animation: $name $sec infinite alternate ease-in-out;

	@keyframes effect--fadeInOut {
		0% 		{ filter: blur(8px);opacity: .6;}
		100% 	{ filter: blur(16px);opacity: 0.2;}
	}
}

// Grain Effect
.effect--grain:after {
	animation: grain 8s steps(10) infinite;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png);
  content: "";
  height: 300%;
  left: -50%;
  opacity: 0.3;
  position: fixed;
  top: -110%;
	width: 300%;
	
	@keyframes grain {
		0%, 100% { transform:translate(0, 0) }
		10% { transform:translate(-5%, -10%) }
		20% { transform:translate(-15%, 5%) }
		30% { transform:translate(7%, -25%) }
		40% { transform:translate(-5%, 25%) }
		50% { transform:translate(-15%, 10%) }
		60% { transform:translate(15%, 0%) }
		70% { transform:translate(0%, 15%) }
		80% { transform:translate(3%, 35%) }
		90% { transform:translate(-10%, 10%) }
	}
}



// Wave Effect
@mixin effect--wave($sec: 4s ) {
  $name: keyframe-wave;
	animation-name: $name;
	animation-duration: $sec;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	
	@keyframes keyframe-wave {
		0% {
			transform: translateY(0px);
		}
		50% {
			transform: translateY(10px);
		}
		100% {
			transform: translateY(0px);
		}
	}
}

