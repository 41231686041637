.contents-nav {
  position: fixed;
  padding-top: 108px;
  left: 0px;
  line-height: 1.5rem;
  z-index: $contents-nav;
  height: 100%;

  .contents__list {
    list-style-position: inside;
    padding: 20px;
    margin: 0;
    background: $midnight;
  }

  a {
    color: $hotPink;
    transition: 0.3s ease-in-out;

    &:hover {
      text-decoration: line-through;
    }
  }

  @media (max-width: $tablet-l) {
    display: none;
  }
}
