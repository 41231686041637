@import '../../styles.scss';

.uses__container main {
  // line-height: 2rem;

  h1,
  h2 {
    font-family: $font-family_roadRage !important;
  }

  section {
    margin-bottom: 8%;

    // only way to psedo select on class
    @for $i from 1 to 8 {
      &:nth-child(#{$i}) {
        .title {
          animation: flicker linear 4s + ($i * 0.2) infinite;
        }
      }
    }
  }

  .title {
    font-size: $font-size-48;
    color: $hotPink;

    &:hover,
    &:focus {
      animation: flicker linear 3s infinite;
    }
  }

  .heading__intro {
    color: $nightWolf;
  }

  .no-list-style:before {
    display: none;
  }

  .podcasts svg {
    margin-left: 10px;

    &.svg-icon--spotify {
      fill: #1ed760;
    }
    &.svg-icon--googlepodcasts {
      fill: #4285f4;
    }
    &.svg-icon--applepodcasts {
      fill: #9933cc;
    }
  }

  ul {
    margin: 0;
    padding-left: 25px;
    list-style-type: none;
    text-indent: -11px;
    margin-left: 10px;

    > li {
      margin-bottom: 14px;

      &:before {
        content: '-';
        color: $hotPink;
        text-indent: -5px;
        margin-right: 10px;
        display: inline-block;
      }
    }

    li {
      a {
        border-bottom: 1px solid rgba($electroShock, 0.6);
        display: inline;
      }

      a:hover {
        color: $hotPink;
      }

      a:after {
        content: '';
        display: inline;
        border-bottom: 1px solid rgba($electroShock, 0.6);
        color: #ffffff;
        transition: 0.3s ease-in-out;
      }

      a:hover:after {
        transform: translatey(-1rem);
        border-color: $hotPink;
      }
    }
  }

  .config__cards {
    img {
      width: 50%;
      text-align: center;
    }

    #mouse-dpi {
      list-style: none;
      padding: 0;

      &:before {
        content: '-';
        color: $hotPink;
        margin-right: 10px;
      }

      li {
        display: inline-block;
        margin-right: 10px;

        &:not(:last-child):after {
          content: '/';
          color: $hotPink;
          margin-left: 10px;
        }
      }
    }

    .config-card--keyboard img {
      transform: rotate(15deg);
    }

    .config-card--mouse {
      .primary i,
      .secondary i {
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 50%;
        border: 1px solid #ffffff;
        display: inline-block;
        margin-left: 14px;
        transform: translateY(3px);
      }

      .primary i {
        background: $hotPink;
      }
      .secondary i {
        background: $electroShock;
      }

      .shift {
        color: $miamiSun;
      }

      .default {
        color: $hotPink;
      }
    }

    .config-card--vscode img {
      // animation: effect--floating-vscode 4s ease-in-out infinite;
      // box-shadow: 0 0 10px rgba($hotPink, 0.5);
    }
  }

  .uses__media .podcasts a {
    border: none;
  }

  @media (max-width: $tablet-l) {
    ul {
      padding-left: 0;
    }
    .config__cards {
      display: none;
    }
  }
}

@keyframes effect--floating-vscode {
  0% {
    transform: translatey(0px) skew(-2deg, 19deg) perspective(900px) rotateY(45deg) rotateX(15deg) rotateZ(-10deg);
  }
  50% {
    transform: translatey(-30px) skew(-2deg, 19deg) perspective(900px) rotateY(45deg) rotateX(15deg) rotateZ(-10deg);
  }
  100% {
    transform: translatey(0px) skew(-2deg, 19deg) perspective(900px) rotateY(45deg) rotateX(15deg) rotateZ(-10deg);
  }
}
